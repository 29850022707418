(function ($) {
  Drupal.behaviors.localeSwitchWarning = { // warn if user tries to switch locale and cart is not empty
    attach: function (context, settings) {
      var prefLanguage, prefCountry, cartItemCount;
      var locale = generic.cookie('LOCALE');

      if (locale != '' && locale != null) {
        prefLanguage = locale.split('_')[0];
      }

      var cartItemCount = 0;

      $(document).on('addToCart.success', function (e, cartResponse) {
        if (cartResponse.trans_data.items_count) {
          cartItemCount = cartResponse.trans_data.items_count;
        } else {
          cartItemCount = cartResponse;
        }
      });

      $('body').on('click', '#cancelChangeLocaleButton, #localeSwitchWarning .close-button', function () {
        $('#localeSwitchOverlay').hide();
      });

      $('body').on('click', '#changeLocaleButton', function () {
        site.changeLocale(prefCountry, prefLanguage);
      });

      $('#countryPickerWrap .country-list a').off('click');
      $('#countryPickerWrap .country-list a').each(function () {
        $(this).on('click', function () {
          // user may be switching country. is their current language going to be supported when they land or should we choose the target country's default?

          prefLanguage = site.getAltLanguage(prefLanguage, $(this).data('languages').split(','));

          requestChangeLocale($(this).data('country'), prefLanguage, $(this).data('countryname'));
        });
      });

      $('#mobileCountryLink').off('click');
      $('#mobileCountryLink').on('click', function () {
        requestChangeLocaleMobile($(this).data('countryname'));
      });

      $(window).resize(function () {
        site.centerModal($('#localeSwitchWarning'));
      });

      function requestChangeLocaleMobile(countryName) {
        if (cartItemCount > 0) { // if cart has items, show the warning
          $('#localeSwitchWarning').html($('#localeSwitchWarning').html().replace('{{{country_name}}}', '<span id="#destinationCountryname">' + countryName + '</span>'));
          $('#destinationCountryname').html(countryName);

          $('body').append($('#localeSwitchOverlay').css({ display: 'block' }));
          site.centerModal($('#localeSwitchWarning'));

          $('body').off('click', '#changeLocaleButton');
          $('body').on('click', '#changeLocaleButton', function () {
            $(document).trigger('displayLocaleInterstitial');
          });
        } else { // if not, show the interstitial
          $(document).trigger('displayLocaleInterstitial');
        }
      }

      function requestChangeLocale(prefC, prefL, countryName) {
        prefCountry = prefC;
        prefLanguage = prefL;

        if (cartItemCount > 0) { // if cart has items, show the warning
          $('#localeSwitchWarning').html($('#localeSwitchWarning').html().replace('{{{country_name}}}', '<span id="#destinationCountryname">' + countryName + '</span>'));
          $('#destinationCountryname').html(countryName);

          $('body').append($('#localeSwitchOverlay').css({ display: 'block' }));
          site.centerModal($('#localeSwitchWarning'));
        } else { // if not, change straight away
          site.changeLocale(prefCountry, prefLanguage);
        }
      }
    }
  };
})(jQuery);
